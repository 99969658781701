@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700%7CRoboto:300,400,500,600,700");
@import "../node_modules/a3dn-shared-lib/assets/css/a3dn-style.scss";
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/angular-calendar/scss/angular-calendar.scss";

:root {
	font-size: 14px;
	--blue: #007bff;
	--indigo: #6610f2;
	--purple: #6f42c1;
	--pink: #e83e8c;
	--red: #dc3545;
	--orange: #fd7e14;
	--yellow: #ffc107;
	--green: #28a745;
	--teal: #20c997;
	--cyan: #17a2b8;
	--white: #fff;
	--gray: #6c757d;
	--gray-dark: #343a40;
	--primary: #5867dd;
	--secondary: #e1e1ef;
	--success: #0abb87;
	--info: #5578eb;
	--warning: #ffb822;
	--danger: #fd397a;
	--light: #f8f9fa;
	--dark: #343a40;
	--breakpoint-xs: 0;
	--breakpoint-sm: 576px;
	--breakpoint-md: 768px;
	--breakpoint-lg: 1024px;
	--breakpoint-xl: 1399px;
	--font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
		"Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	--font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New",
		monospace;
}
* {
	box-sizing: border-box;
}
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
body {
	margin: 0;
	font-family: Poppins, sans-serif;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #646c9a;
	text-align: left;
	background-color: #fff;
}
html,
body {
	height: 100%;
}
@media only screen and (max-width: 800px) {
	:root {
		font-size: 12px;
	}
}
a {
	&:hover {
		outline: 0 !important;
		text-decoration: none !important;
	}
	outline: 0 !important;
}

.mat-tab-label {
	min-width: 0 !important;
}
div[id^="ringover-tray-"] {
	height: 0 !important;
}
div[id^="ringover-cross-"] {
	background-clip: unset !important;
}
div[id^="ringover-iframe-container-"] {
	background-color: white;
}
.mat-tab-header {
	margin-bottom: 0.6rem !important;
}
ngb-popover-window {
	position: absolute;
	width: auto !important;
	display: block !important;
	background-color: #fafafa !important;
	border: 1px solid #fafafa !important;
	max-width: none !important;
	z-index: 1000 !important;
}

.mat-tab-label {
	padding: 0 8px !important;
}
